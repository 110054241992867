import React from 'react';

import Header from '../components/header/header';
import {
  Decoration2,
  BorderBox1,
} from '@jiaminghi/data-view-react';
import DocumentFlow from "../components/right/DocumentFlow";
import ObjectFlow from "../components/right/ObjectFlow";
import '../css/DisplayView.css'; import '../css/FlowView.css';
import { getProductData } from '../services/Services';
import DocumentTotal from '../components/flow-view/DocumentTotal';
import config from '../config';

class FlowView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product: []
    };
  }

  componentDidMount() {
    const callback = (response) => {
      console.log(response);
      this.setState({
        product: response.data
      })
    };

    getProductData(config.address, callback);
  }

  render() {
    return (
      <div className="display-view">
        <div className="view-header">
          <Header />
        </div>
        <div className="flow-content">
          <BorderBox1>
            <div className="document-flow-left">
              <DocumentFlow data={this.props.data} />
            </div>
          </BorderBox1>
          <Decoration2 reverse={true} style={{ width: '10px', height: '600px' }} />
          <BorderBox1>
            <div className="object-flow-right">
              <ObjectFlow data={this.props.data} />
            </div>
          </BorderBox1>
        </div>
        <div className="document-table">
          <BorderBox1>
            <DocumentTotal data={this.props.data} />
          </BorderBox1>
        </div>
      </div>
    );
  }
}

export default FlowView;