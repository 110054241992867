import React from 'react';
import * as echarts from "echarts";

import '../../css/right/DocumentFlow.css';

import Supplier from '../../assets/pictures/供应商.jpg';
import Manufacturer from '../../assets/pictures/制造商.jpg';
import Customer from '../../assets/pictures/客户.jpg';
import Icon2 from '../../assets/pictures/icon2.png';
import { getDocLatestEventData } from '../../services/Services';
import config from '../../config';
let legend = {
    show: true,
    top: '5%',
    right: '3%',
    textStyle: { color: '#fff' },
    itemWidth: 20,
    itemHeight: 20,
    orient: 'vertical',
    data: [
        { name: '空闲', itemStyle: { color: "rgba(128, 128, 128, 1)" } },
        { name: '正在发生', itemStyle: { color: "rgba(255,168,2,1)" } }
    ],
};
let series = {
    type: 'graph',
    layout: 'none', // 图的布局
    symbolSize: [60, 30],
    categories: [
        { name: '空闲', itemStyle: { color: "rgba(128, 128, 128, 1)" } },
        { name: '正在发生', itemStyle: { color: "rgba(255,168,2,1)" } }
    ],
    label: {
        show: true,
        align: 'center',
        textStyle: { fontSize: 12, color: '#fff' },
        fontWeight: 'bold'
    },
    edgeSymbol: ['circle', 'arrow'],
    edgeSymbolSize: [4, 8],
    edgeLabel: {
        show: true,
        textStyle: { fontSize: 10 },
        formatter: '{c}',
        rotate: 0,
        // color: '#fff',
        // padding: [4, 5],
        // backgroundColor: 'rgba(128, 128, 128, 1)'
    },
    data: [
        /*-45，-3, 34*/
        { id: '1', name: '生成订单', category: 0, x: -45, y: 0 },
        { id: '2', name: '确认订单', category: 0, x: -3, y: 0 },
        { id: '3', name: '订单付款', category: 0, x: -3, y: 7 },
        { id: '4', name: '确认收款', category: 0, x: -45, y: 7 },
        { id: '5', name: '开具发票', category: 0, x: -45, y: 14 },
        { id: '6', name: '物流发货', category: 0, x: -45, y: 21 },
        { id: '7', name: '确认收货', category: 0, x: -3, y: 21 },
        { id: '8', name: '原料入库', category: 0, x: -3, y: 28 },

        { id: '9', name: '生成订单', category: 0, x: -3, y: 35 },
        { id: '10', name: '确认订单', category: 0, x: 34, y: 35 },
        { id: '11', name: '产品出库', category: 0, x: -3, y: 42 },
        { id: '12', name: '开具发票', category: 0, x: -3, y: 49 },
        { id: '13', name: '物流发货', category: 0, x: -3, y: 56 },
        { id: '14', name: '确认收货', category: 0, x: 34, y: 56 },
        { id: '15', name: '订单付款', category: 0, x: 34, y: 63 },
        { id: '16', name: '确认收款', category: 0, x: -3, y: 63 },
    ],
    links: [
        { source: '1', target: '2', value: '订单' },
        {
            source: '2', target: '3', value: '',
            lineStyle: {
                curveness: 2
            }
        },
        { source: '3', target: '4', value: '付款单' },
        {
            source: '4', target: '5', value: '',
            lineStyle: {
                curveness: -2
            }
        },
        {
            source: '5', target: '6', value: '发票',
            lineStyle: {
                curveness: -2
            },
        },
        { source: '6', target: '7', value: '物流单' },
        {
            source: '7', target: '8', value: '入库单',
            lineStyle: {
                curveness: 2
            },
        },

        { source: '9', target: '10', value: '订单' },
        { source: '10', target: '11', value: '' },
        {
            source: '11', target: '12', value: '出库单',
            lineStyle: {
                curveness: -2
            },
        },
        {
            source: '12', target: '13', value: '发票',
            lineStyle: {
                curveness: 2
            },
        },
        { source: '13', target: '14', value: '物流单' },
        {
            source: '14', target: '15', value: '',
            lineStyle: {
                curveness: 2
            },
        },
        { source: '15', target: '16', value: '付款单' },

    ],
    lineStyle: {
        opacity: 0.9,
        width: 1,
        color: '#fff',
    },
};

class DocumentFlow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }

    componentDidMount() {
        const callback = (response) => {
            if (response.data == this.state.data) return;
            let index = parseInt(response.data['bizstep'].substring(1, 3)) - 1;
            series.data[index].category = 1;
            for (let i = 0; i < 16; i++) {
                if (i === index)
                    series.data[i].category = 1;
                else
                    series.data[i].category = 0;
            }
            this.setState({ data: response.data })
            this.InitialDocumentFlow({ series, legend });
        };

        let option = { series, legend };
        this.InitialDocumentFlow(option);

        this.timer = setInterval(() => {
            getDocLatestEventData(config.address, callback);
        }, 2000)
    }

    InitialDocumentFlow(option) {
        const chartDom = document.getElementById('document-flow');
        const myChart = echarts.init(chartDom);
        option && myChart.setOption(option);
    }

    // componentDidUpdate(prevProps, prevState) {
    //     console.log(this.props.data);
    //     if (this.props !== prevProps) {

    //         if (this.props.data && this.props.data['bizstep'][0] === 'd') {
    //             let index = parseInt(this.props.data['bizstep'].substring(1, 3)) - 1;
    //             this.callback(index)
    //         }
    //     }
    // }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    render() {
        return (
            <div className="right-document-flow">
                <div className="right-document-flow-title">
                    {/* <img src={Icon2} alt="icon" /> */}
                    <div className="right-document-flow-title-content">
                        单据流/实物流
                    </div>
                </div>
                <div className="right-document-flow-header">
                    <div>
                        <div className="right-document-flow-name">供应商</div>
                        <img src={Supplier} alt="Supplier" style={{ height: "60px" }} />
                    </div>
                    <div>
                        <div className="right-document-flow-name">制造商</div>
                        <img src={Manufacturer} alt="Manufacturer" style={{ height: "60px" }} />
                    </div>
                    <div>
                        <div className="right-document-flow-name">客户</div>
                        <img src={Customer} alt="Customer" style={{ height: "60px" }} />
                    </div>
                </div>
                <div className="document-flow" id="document-flow" />
            </div>
        );
    }
}

export default DocumentFlow;
