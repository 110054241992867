import React from 'react';
import * as echarts from "echarts";

import '../../css/right/ObjectFlow.css';
import { getObjectLatestEventData } from '../../services/Services';
import config from '../../config';
let legend = {
    show: true,
    top: '5%',
    right: '3%',
    textStyle: { color: '#fff' },
    itemWidth: 20,
    itemHeight: 20,
    orient: 'vertical',
    data: [
        { name: '空闲', itemStyle: { color: "rgba(128, 128, 128, 1)" } },
        { name: '正在发生', itemStyle: { color: "rgba(255,168,2,1)" } }
    ],
};
let series = {
    type: 'graph',
    layout: 'none', // 图的布局
    symbolSize: [60, 30],
    categories: [
        { name: '空闲', itemStyle: { color: "rgba(128, 128, 128, 1)" } },
        { name: '正在发生', itemStyle: { color: "rgba(255,168,2,1)" } }
    ],
    label: {
        show: true,
        align: 'center',
        textStyle: { fontSize: 12, color: '#fff' },
        fontWeight: 'bold'
    },
    edgeSymbol: ['circle', 'arrow'],
    edgeSymbolSize: [4, 8],
    edgeLabel: {
        show: true,
        textStyle: { fontSize: 10 },
        formatter: '{c}',
        rotate: 0,
        // color: '#fff',
        // padding: [4, 5],
        // backgroundColor: 'rgba(128, 128, 128, 1)'
    },
    data: [
        /*-22，-8, 34*/
        { id: '1', name: '物流发货', category: 0, x: -22, y: 0 },
        { id: '2', name: '确认收获', category: 0, x: 8, y: 0 },
        { id: '3', name: '原料入库', category: 0, x: 8, y: 7 },
        { id: '4', name: '单品制造', category: 1, x: 8, y: 14 },
        { id: '5', name: '单品装箱', category: 0, x: 8, y: 21 },
        { id: '6', name: '整箱出库', category: 0, x: 8, y: 28 },
        { id: '7', name: '物流发货', category: 0, x: 8, y: 35 },
        { id: '8', name: '确认收货', category: 0, x: 34, y: 35 },
    ],
    links: [
        { source: '1', target: '2', value: '' },
        {
            source: '2', target: '3', value: '',
            lineStyle: {
                curveness: 2
            }
        },
        {
            source: '3', target: '4', value: '',
            lineStyle: {
                curveness: -2
            }
        },
        {
            source: '4', target: '5', value: '',
            lineStyle: {
                curveness: 2
            }
        },
        {
            source: '5', target: '6', value: '',
            lineStyle: {
                curveness: -2
            },
        },
        {
            source: '6', target: '7', value: '',
            lineStyle: {
                curveness: 2
            }
        },
        { source: '7', target: '8', value: '' },
    ],
    lineStyle: {
        opacity: 0.9,
        width: 1,
        color: '#fff',
    },
};

class ObjectFlow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }

    componentDidMount() {
        const callback = (response) => {
            if (response.data == this.state.data) return;
            let index = parseInt(response.data['bizstep'][1]) - 1;
            series.data[index].category = 1;
            for (let i = 0; i < 8; i++) {
                if (i === index)
                    series.data[i].category = 1;
                else
                    series.data[i].category = 0;
            }
            this.setState({ data: response.data })
            this.InitialDocumentFlow({ series, legend });

        };
        let option = { series, legend };
        this.InitialDocumentFlow(option);
        this.timer = setInterval(() => {
            getObjectLatestEventData(config.address, callback);
        }, 2000)
    }


    InitialDocumentFlow(option) {
        const chartDom = document.getElementById('object-flow');
        const myChart = echarts.init(chartDom);

        option && myChart.setOption(option);
    }

    // componentDidUpdate(prevProps, prevState) {
    //     console.log(this.props.data);
    //     if (this.props !== prevProps) {

    //         if (this.props.data && this.props.data['bizstep'][0] === 'p') {
    //             let index = parseInt(this.props.data['bizstep'][1]) - 1;
    //             this.callback(index)
    //         }
    //     }
    // }
    componentWillUnmount() {
        clearInterval(this.timer)
    }
    render() {
        return (
            <div className="right-object-flow">
                <div className="object-flow" id="object-flow" />
            </div>
        );
    }
}

export default ObjectFlow;
