import React from 'react'
import { Decoration3 } from '@jiaminghi/data-view-react';
import * as echarts from "echarts";

import '../../css/middle/MiddleChart.css';

import { getSteelData } from "../../services/Services";
import config from '../../config';
class MiddleChart extends React.Component {

    constructor(props) {
        super(props);

        this.InitialChart = this.InitialChart.bind(this);
        this.UpdateSteelData = this.UpdateSteelData.bind(this);
    }


    componentDidMount() {
        this.InitialChart();

        const callback = (response) => {
            let num = response.data.length;
            let rangeDate = new Array(num);
            let monthDay = new Array(num);

            let value = new Array(num);
            let maxValue = 0;
            let minValue = 0;
            let intervalValue = 0;

            /* 获取需要的日期信息 */
            for (let i = 0; i < num; i++) {
                let rawDate = response.data[i].date;
                let splitIndex = rawDate.indexOf('T');
                rangeDate[i] = rawDate.substr(0, splitIndex);
            }

            /* 对日期进行格式化处理 */
            for (let i = 0; i < num; i++) {
                let dateInfo = rangeDate[i].split('-');
                monthDay[i] = dateInfo[1] + '/' + dateInfo[2];
            }

            /* 获取具体数据 */
            for (let i = 0; i < num; i++) {
                value[i] = response.data[i].value;
            }

            this.UpdateSteelData('middle-chart-content', { value: value, date: monthDay });
        };

        getSteelData(config.address, callback);

        const timer = setInterval(() => {
            getSteelData(config.address, callback);
        }, 1000 * 60 * 60 * 24);
    }

    InitialChart() {
        let chartDom = document.getElementById('middle-chart-content');
        const myChart = echarts.init(chartDom);
        let option;

        option = {
            legend: {
                data: ['原料价格'],
                textStyle: { color: '#fff' },
                bottom: '2%',
            },
            xAxis: {
                data: [],
                axisLine: {
                    lineStyle: {
                        color: 'rgb(153, 153, 153)',
                    },
                },
                axisLabel: {
                    color: 'rgb(153, 153, 153)',
                },
                axisTick: {
                    show: false,
                },
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgb(60, 60, 60)',
                    },
                    show: true
                },
                axisLabel: {
                    color: 'rgb(153, 153, 153)',
                },
                axisTick: {
                    show: false,
                },
            },
            series: [
                {
                    data: [],
                    type: 'line',
                    name: '原料价格',
                    smooth: true,
                    lineStyle: {
                        color: 'rgb(99, 125, 203)'
                    },
                    areaStyle: {
                        show: true,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: 'rgba(55, 162, 218, 0.6)' // 0% 处的颜色
                            }, {
                                offset: 1, color: 'rgba(55, 162, 218, 0)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    },
                    itemStyle: {
                        radius: 4,
                        color: '#00db95'
                    },
                },
            ],
        };

        option && myChart.setOption(option);
    }

    UpdateSteelData(elementId, data) {
        const chartDom = document.getElementById(elementId);
        const myChart = echarts.init(chartDom);
        const minValue = Math.min(...data.value);
        const maxValue = Math.max(...data.value);

        myChart.setOption({
            series: [
                {
                    data: data.value,
                }
            ],
            xAxis: {
                data: data.date,
            },
            yAxis: {
                min: (Math.floor(minValue / 100) - 1) * 100,
                max: (Math.ceil(maxValue / 100) + 1) * 100,
            }
        });
    }

    render() {
        return (
            <div className="middle-chart">
                <div className="middle-chart-title">
                    <div className="middle-chart-name"> 生产原料实时价格 </div>
                    <Decoration3 style={{ width: '200px', height: '20px' }} />
                </div>
                <div className="middle-chart-content" id="middle-chart-content" />
            </div>
        )
    }
}

export default MiddleChart;
