import DisplayView from "./view/DisplayView";
import React from "react";

import FlowView from "./view/FlowView";
import Router from "./view/Router";

function App() {

  return (
    <Router />
  );
}

export default App;
