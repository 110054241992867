import React from 'react';
import { ScrollBoard } from '@jiaminghi/data-view-react'

import '../../css/left/LeftScrollBoard.css';
import Icon1 from '../../assets/pictures/icon1.png';

import { getProductData } from '../../services/Services';
import config from '../../config';

const map = {
    "20097505_3424320_1": '液位计1',
    "20098740_3424320_2": '液位计2',
    "20097506_3424320_1": '液位计3',
    "20098740_3422465_1": '液位计4',
    "2009xxxx_3424yyy_z": '液位计5'
}

const configModel = {
    header: ['传感器名称', '检查时间', '设备状态'],
    data: [],
    headerHeight: 30,
    headerBGC: '#1981f6',
    columnWidth: [120, 180, 100],
    align: ['center', 'center', 'center'],
    oddRowBGC: 'rgba(0, 44, 81, 0.8)',
    evenRowBGC: 'rgba(10, 29, 50, 0.8)',
    hoverPause: false
};

class LeftScrollBoard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [

            ],
        };
    }

    componentDidMount() {
        const callback = (response) => {
            // console.log(response.data);

            let rawData = response.data;
            let dataLength = rawData.length;

            let newData = new Array(dataLength);

            for (let i = 0; i < dataLength; i++) {
                let singleData = new Array(3);
                singleData[0] = map[rawData[i].tankno];
                singleData[1] = rawData[i].eventdate;
                singleData[2] = rawData[i].eventyype;

                newData[i] = singleData;
            }

            this.setState({
                data: newData
            });
        };
        getProductData(config.address, callback);

        this.timer = setInterval(() => {
            getProductData(config.address, callback);
        }, 60000);
    }
    componentWillUnmount() {
        clearInterval(this.timer)
    }

    render() {
        return (
            <div className="left-scroll-board">
                <div className="left-board-title">
                    <img src={Icon1} alt="icon" />
                    <div className="title-content"> 设备维护 </div>
                </div>
                <ScrollBoard className="scroll-board" id="scroll-board" config={{ ...configModel, data: this.state.data }} />
            </div>
        );
    }
}

export default LeftScrollBoard;
