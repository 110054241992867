import axios from 'axios';

const axiosRequest = (method, url, data, callback) => {

    const requestConfig = {
        method: method,
        url: url,
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
    };

    axios(requestConfig)
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            console.log(error);
        })
};

export const get = (url, data, callback) => axiosRequest('get', url, data, callback);

export const post = (url, data, callback) => axiosRequest('post', url, data, callback);

export const put = (url, data, callback) => axiosRequest('put', url, data, callback);
