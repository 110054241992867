import React from 'react';
import '../../css/middle/VideoPlayer.css';
import '../../services/Services'
import 'videojs-flash'
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
import config from '../../config';


class VideoPlayer extends React.Component {
    componentDidMount() {
        const videoJsOptions = {
            autoplay: true,
            controls: true,
            muted: true,
            sources: [{
                src: config.videoUrl,
                type: 'application/x-mpegURL'
            }]
        };
        this.player = videojs(this.videoNode, videoJsOptions, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    render() {
        return (
            <div>
                <div className="video-player" >
                    <video ref={node => this.videoNode = node} width="640px" height="400px" className='video-js' />
                </div>
            </div>
        );
    }
}

export default VideoPlayer;
