import React from 'react';
import { Table, Tag, Space } from 'antd';
import { ScrollBoard } from '@jiaminghi/data-view-react'
import '../../css/left/LeftScrollBoard.css';
import '../../css/flow-view/DocumentTotal.css';
import 'antd/dist/reset.css';
import { getEventData } from '../../services/Services';
import { time } from 'echarts';
import config from '../../config';

const configModel = {
  header: ['时间', 'EPC编码', '事件类型', '发生地点'],
  data: [],
  rowNum: 8,
  headerHeight: 30,
  headerBGC: '#1981f6',
  columnWidth: [400, 500, 400, 400],
  align: ['center', 'center', 'center', 'center'],
  oddRowBGC: 'rgba(0, 44, 81, 0.8)',
  evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  hoverPause: false
};
const map = {
  p1: '物流发货',
  p2: '确认收货',
  p3: '原料入库',
  p4: '单品制造',
  p5: '单品装箱',
  p6: '整箱出库',
  p7: '物流发货',
  p8: '确认收货',
  d1: '生成订单',
  d2: '确认订单',
  d3: '订单付款',
  d4: '确认收款',
  d5: '开具发票',
  d6: '物流发货',
  d7: '确认收货',
  d8: '原料入库',
  d9: '生成订单',
  d10: '确认订单',
  d11: '产品出库',
  d12: '开具发票',
  d13: '物流发货',
  d14: '确认收货',
  d15: '订单付款',
  d16: '确认收款',
}
class DocumentTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }
  componentDidMount() {
    const callback = (response) => {
      // let event = response.data;
      // event['key'] = '1';
      // console.log(event)
      let rawData = response.data;
      let dataLength = 8;

      let newData = new Array(dataLength);

      for (let i = 0; i < dataLength; i++) {
        let singleData = new Array(4);

        if (rawData[i].eventtime.length > 20) {
          singleData[0] = rawData[i].eventtime.split('T')[0] + ' ' + rawData[i].eventtime.split('T')[1].split('.')[0]
        }
        else singleData[0] = rawData[i].eventtime
        // singleData[0] = time;
        singleData[1] = rawData[i].epc;
        singleData[2] = map[rawData[i].bizstep];
        singleData[3] = rawData[i].readpoint;

        newData[i] = singleData;
      }

      this.setState({
        data: newData
      });
    };

    getEventData(config.address, callback);

    const timer = setInterval(() => {
      getEventData(config.address, callback);
      // console.log(2);
    }, 2000)
  }
  componentWillUnmount() {
    clearInterval(this.timer)
  }
  render() {
    return (
      <div className="document-total">
        <div className="document-info-title">
          当前进度
        </div>
        <ScrollBoard config={{ ...configModel, data: this.state.data }} style={{ width: '1700px', height: '300px' }} />
        {/* <Table showHeader={true} columns={columns} dataSource={this.state.data} pagination={{ position: ['none', 'none'] }} bordered={true} rowClassName="info-row-class" /> */}
      </div>
    );
  }
}

export default DocumentTotal;
