import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config'

fetch('/config.json')
  .then((response) => response.json())
  .then((data) => {
    console.log(data)
    Object.assign(config, data); // Update the configuration with fetched values
    console.log(config)
    // Render the React app after the configuration has been updated
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((error) => {
    console.error('Failed to fetch configuration:', error);
  });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
