import React from 'react';
import * as echarts from "echarts";

import '../../css/left/LeftRingChart.css';

import { getLatestTankData } from '../../services/Services';
import config from '../../config';

const map = {
    "20097505_3424320_1": 0,
    "20098740_3424320_2": 1,
    "20097506_3424320_1": 2,
    "20098740_3422465_1": 3,
    "2009xxxx_3424yyy_z": 4
}
class LeftRingChart extends React.Component {

    constructor(props) {
        super(props);

        this.InitialCharts = this.InitialCharts.bind(this);
        this.UpdateTankData = this.UpdateTankData.bind(this);
    }

    componentDidMount() {
        this.InitialCharts(1);
        this.InitialCharts(2);
        this.InitialCharts(3);
        this.InitialCharts(4);
        this.InitialCharts(5);

        const callback = (response) => {
            let tankData = new Array(5);

            for (let i = 0; i < 5; i++) {
                /* index range: 1\2\3 */
                const index = map[response.data[i].tankno];
                // if (index > 3 || index < 1) {
                //     console.log("index overflow");
                //     break;
                // }
                tankData[index] = response.data[i].currentvolume;
            }

            this.UpdateTankData('chart1', tankData[0]);
            this.UpdateTankData('chart2', tankData[1]);
            this.UpdateTankData('chart3', tankData[2]);
            this.UpdateTankData('chart4', tankData[3]);
            this.UpdateTankData('chart5', tankData[4]);
        };

        getLatestTankData(config.address, callback);

        const timer = setInterval(() => {
            getLatestTankData(config.address, callback);
        }, 10000);
    }

    InitialCharts(elementId) {
        const chartDom = document.getElementById('chart' + elementId);
        const myChart = echarts.init(chartDom);
        let option;

        option = {
            title: {
                text: '传感器示数0' + elementId,
                textStyle: {
                    color: '#ffffff',
                    fontWeight: "normal",
                    fontSize: 16,
                },
                left: '10',
                top: '0',
            },
            series: [
                {
                    name: '传感器示数' + elementId,
                    type: 'pie',
                    hoverAnimation: false,
                    radius: ["60%", "70%"],
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                const colorList = ['#2D84FB', '#ffffff'];
                                return colorList[params.dataIndex];
                            }
                        }
                    },
                    label: {
                        normal: {
                            textStyle: {
                                fontSize: 12,
                                color: "#2D84FB",
                            },
                            formatter: "{d}%",
                            position: "center",
                            show: false,
                        }
                    },
                    data: [
                        {
                            name: "传感器示数",
                            value: 50,
                            label: { show: true, fontSize: "20", fontWeight: "bold" },
                        },
                        {
                            name: "空白",
                            value: 3
                        }
                    ],
                }
            ]
        };

        option && myChart.setOption(option);
    }

    UpdateTankData(elementId, value) {
        const chartDom = document.getElementById(elementId);
        const myChart = echarts.init(chartDom);
        myChart.setOption({
            series: [{
                // 根据名字对应到相应的系列
                // name: '传感器示数1',
                data: [
                    {
                        name: "传感器示数",
                        value: value,
                        label: { show: true, fontSize: "25", fontWeight: "bold" },
                    },
                    {
                        name: "空白",
                        value: 1 - value
                    }
                ],
            }]
        });
    }

    render() {
        return (
            <div className="left-ring-chart">
                <div className="chart" id="chart1" />
                <div className="chart" id="chart2" />
                <div className="chart" id="chart3" />
                <div className="chart" id="chart4" />
                <div className="chart" id="chart5" />
            </div>
        );
    }
}

export default LeftRingChart;
