import React from 'react';

import Header from '../components/header/header';
import {
    Decoration2,
    Decoration4,
    Decoration10,
    BorderBox1,
    BorderBox11
} from '@jiaminghi/data-view-react';

import LeftScrollBoard from "../components/left/LeftScrollBoard";
import LeftRingChart from "../components/left/LeftRingChart";
import VideoPlayer from "../components/middle/VideoPlayer";
import MiddleChart from "../components/middle/MiddleChart";
import DocumentFlow from "../components/right/DocumentFlow";
import ObjectFlow from "../components/right/ObjectFlow";
import DocumentInfo from "../components/right/DocumentInfo";

import '../css/DisplayView.css';


class DisplayView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="display-view">
                <div className="view-header">
                    <Header />
                </div>
                <div className="view-content">
                    <div className="content-left">
                        <LeftScrollBoard />
                        <LeftRingChart />
                    </div>
                    <Decoration2 reverse={true} style={{ width: '5px', height: '1000px', marginLeft: '20px' }} />
                    <div className="content-middle">
                        <BorderBox11 style={{ width: '700px', height: '500px', marginLeft: '50px' }} title="监控摄像" >
                            <VideoPlayer />
                        </BorderBox11>
                        <Decoration10 style={{ width: '700px', height: '5px', marginLeft: '50px', marginTop: '20px' }} />
                        <MiddleChart />
                    </div>
                    <Decoration2 reverse={true} style={{ width: '5px', height: '1000px', marginLeft: '50px' }} />
                    <div className="content-right">
                        <BorderBox1>
                            <DocumentFlow data={this.props.data} />
                            <Decoration4 reverse={true} style={{ width: '650px', height: '5px', marginLeft: '40px' }} />
                            <ObjectFlow data={this.props.data} />
                            <DocumentInfo data={this.props.data} />
                        </BorderBox1>
                    </div>
                </div>
            </div>
        );
    }
}

export default DisplayView;
