import React from 'react';
import DisplayView from './DisplayView';
import FlowView from './FlowView';
import { getEventData, getLatestEventData } from "../services/Services";
import config from '../config';
let toggleTimer = undefined;

const map = {
  p1: '物流发货',
  p2: '确认收获',
  p3: '原料入库',
  p4: '单品制造',
  p5: '单品装箱',
  p6: '整箱出库',
  p7: '物流发货',
  p8: '确认收货',
  d1: '生成订单',
  d2: '确认订单',
  d3: '订单付款',
  d4: '确认收款',
  d5: '开具发票',
  d6: '物流发货',
  d7: '确认收货',
  d8: '原料入库',
  d9: '生成订单',
  d10: '确认订单',
  d11: '产品出库',
  d12: '开具发票',
  d13: '物流发货',
  d14: '确认收货',
  d15: '订单付款',
  d16: '确认收款',
}

class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisplayView: true,
      data: null,
    }
  }

  callback = (response) => {
    let event = response.data;
    event['key'] = '1';
    event['eventtype'] = map[event['bizstep']]
    if (this.state.data['id'] !== event['id']) {
      clearTimeout(toggleTimer)
      toggleTimer = setTimeout(() => { this.setState({ data: event, isDisplayView: true }) }, 30000)
      this.setState({ data: event })
      if (this.state.isDisplayView)
        this.setState({ isDisplayView: false })
    }
  };

  componentDidMount() {
    const initializeEvent = (response) => {
      let event = response.data;
      event['key'] = '1';
      event['eventtype'] = map[event['bizstep']]
      this.setState({ data: event })
    };

    getLatestEventData(config.address, initializeEvent);

    this.timer = setInterval(() => {
      getLatestEventData(config.address,this.callback);
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    return (
      this.state.isDisplayView ? <DisplayView data={this.state.data} /> : <FlowView data={this.state.data} />
    );
  }
}

export default Router;
