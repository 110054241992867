import React from 'react';
import { Table } from 'antd';

import { getLatestEventData } from "../../services/Services";

import '../../css/right/DocumentInfo.css';
import 'antd/dist/reset.css';


const columns = [
    {
        title: '时间',
        dataIndex: 'eventtime',
        key: 'eventtime',
        align: 'center',
        className: 'column-header-class'
    },
    {
        title: '事件类型',
        dataIndex: 'eventtype',
        key: 'eventtype',
        align: 'center',
        className: 'column-header-class'
    },
    {
        title: '单据/实物EPC',
        dataIndex: 'epc',
        key: 'epc',
        align: 'center',
        className: 'column-header-class'
    },
    {
        title: '发生地点',
        key: 'readpoint',
        dataIndex: 'readpoint',
        align: 'center',
        className: 'column-header-class'
    }
];

const data = [
    {
        key: '1',
        eventtime: '2021-11-15 12:29:58',
        eventtype: 'ObjectEvent',
        epc: '293800000000000000000001',
        readpoint: '供应商',
    }]
class DocumentInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {

            }
        }
    }

    componentDidMount() {
        // let event = this.props.data
        // console.log(event)
        // event['key'] = '1';
        // if (event['eventtime'].length > 20) {
        //     event['eventtime'] = event['eventtime'].split('T')[0] + ' ' + event['eventtime'].split('T')[1].split('.')[0]
        // }
    }
    render() {
        if (this.props.data && this.props.data['eventtime'].length > 20) {
            this.props.data['eventtime'] = this.props.data['eventtime'].split('T')[0] + ' ' + this.props.data['eventtime'].split('T')[1].split('.')[0]
        }
        return (
            <div className="document-info">
                <div className="document-info-title">
                    单据/实物流
                </div>
                <Table showHeader={true} columns={columns} dataSource={[this.props.data]} pagination={{ position: ['none', 'none'] }} bordered={true} rowClassName="info-row-class" />
            </div>
        );
    }
}

export default DocumentInfo;
