import { get } from '../utils/Axios';


export const getProductData = (address, callback) => {
    const url = address + '/api/tankdata/getTankState';
    get(url, {}, callback);
};

export const getTankData = (address, callback) => {
    const url = address + '/api/tankdata/getAll';
    get(url, {}, callback);
};

export const getLatestTankData = (address, callback) => {
    const url = address + '/api/tankdata/getLatest';
    get(url, {}, callback);
};

export const getLatestEventData = (address, callback) => {
    const url = address + '/api/event/getLatest';
    get(url, {}, callback);
};

export const getEventData = (address, callback) => {
    const url = address + '/api/event/getAll';
    get(url, {}, callback);
};

export const getObjectLatestEventData = (address, callback) => {
    const url = address + '/api/event/getObjectLatest';
    get(url, {}, callback);
};

export const getDocLatestEventData = (address, callback) => {
    const url = address + '/api/event/getDocLatest';
    get(url, {}, callback);
};

export const getSteelData = (address, callback) => {
    const url = address + '/api/steel/getAll';
    get(url, {}, callback);
};
