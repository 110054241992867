import React from 'react';
import { Decoration5, Decoration8 } from '@jiaminghi/data-view-react'

import '../../css/header/header.css';

class Header extends React.Component {
    render(){
        return(
            <div className="header-all">
                <Decoration8 className="header-left-decoration" />
                <Decoration5 className="header-center-decoration" />
                <Decoration8 className="header-right-decoration" reverse={true} />
                <div className="center-title">智慧工厂运行状态监控</div>
            </div>
        );
    }
}

export default Header;
